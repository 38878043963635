<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-screen">
				<el-form inline size="small">
					<el-form-item :label="$t('teacher.formItem.orderSn')">
						<el-input v-model="screenForm.order_sn" clearable :placeholder="$t('teacher.placeholder.orderSn')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.shop')">
						<el-input v-model="screenForm.store_name" class="w_all" clearable :placeholder="$t('teacher.placeholder.shopName')">
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.orderStatus')">
						<el-select v-model="screenForm.status" class="w_all" clearable>
							<el-option v-for="(item,index) in statusOption" :key="index" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.orderTime')">
						<el-date-picker v-model="datetime" class="w_all" type="daterange" value-format="yyyy-MM-dd"
							range-separator="-" :start-placeholder="$t('teacher.placeholder.startDate')" :end-placeholder="$t('teacher.placeholder.endDate')"
							:picker-options="pickerOptions">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.buyers')">
						<el-input v-model="screenForm.user_name" clearable :placeholder="$t('teacher.placeholder.buyers')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.payType')">
						<el-select v-model="screenForm.pay_type" class="w_all" clearable>
							<el-option v-for="(item,index) in payOption" :key="index" :label="item.payment_name" :value="item.payment_code"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.belongClass')">
						<el-select v-model="screenForm.class_id" class="w_all" clearable>
							<el-option v-for="(item,index) in classOption" :key="index" :label="item.name"
								:value="item.class_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearch"><i class="el-icon-search"></i></el-button>
						<span class="search-clear" @click="handleClearSearch"><i class="el-icon-brush"></i>{{$t('teacher.actions.clear')}}</span>
					</el-form-item>
				</el-form>
			</div>
			<div class="t-page-table">
				<el-table :data="pageList" size="small">
					<el-table-column :label="$t('teacher.tableHead.orderSn')" prop="order_sn" width="260px"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.shop')" prop="store_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.buyers')" prop="user_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.class')" prop="class_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.orderTime')" align="center">
						<template slot-scope="scope">{{scope.row.add_time | parseTime()}}</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.orderAmount')" prop="order_amount" align="center"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.payMode')" prop="payment_name" align="center"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.orderStatus')" align="center">
						<template slot-scope="scope">
							{{scope.row.status | statusFilter($t('teacher.filters.orderStatus'))}}
						</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.actions')" align="center">
						<template slot-scope="scope">
							<router-link class="link-item"
								:to="{name:'teacherOrderInfo',params:{id:scope.row.order_id}}">{{$t('teacher.actions.view')}}</router-link>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="t-page-bottom">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="pageCurr" :page-size="pageSize" layout="total, prev, pager, next"
					:prev-text="$t('teacher.pagination.prevText')" :next-text="$t('teacher.pagination.nextText')" :total="pageTotal">
				</el-pagination>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
	import { fetchClass, getOrderList, fetchPayment } from '@/api/teacher';
	const defaultForm = {
		order_sn: '',
		store_name: '',
		status: '',
		user_name: '',
		pay_type: '',
		class_id: ''
	}
	export default {
		filters: {
			statusFilter(status, option) {
				return option[status]
			}
		},
		data() {
			return {
				screenForm: Object.assign({}, defaultForm),
				statusOption: Object.assign([], this.$t('teacher.option.orderStatus')),
				payOption: [],
				datetime: '',
				pickerOptions: {
					disabledDate(time) {
						let yy = new Date().getFullYear();
						let mm = new Date().getMonth() - 6;
						let dd = new Date().getDate() + 1;
						let start_time = new Date(yy, mm, dd);
						return time.getTime() > Date.now();
					},
				},
				classOption: [],
				pageList: [],
				pageCurr: 1,
				pageSize: 10,
				pageTotal: 0,
			}
		},
		created() {
			if (this.$route.query.begin_time && this.$route.query.end_time) {
				this.datetime = [
					this.$route.query.begin_time,
					this.$route.query.end_time
				]
			}
			this.getClassOption();
			this.getPageList();
			this.getPayment()
		},
		methods: {
			getClassOption() {
				let params = {
					page_index: 0,
					page_size: 1000
				}
				fetchClass(params).then(response => {
					this.classOption = response.data.list;
				})
			},
			getPayment() {
				fetchPayment().then(response => {
					this.payOption = response.data
				})
			},
			getPageList() {
				let params = {
					...this.screenForm,
					page_index: (this.pageCurr - 1) * this.pageSize,
					page_size: this.pageSize
				}
				if(this.$route.query.begin_time) {
					params.begin_time = this.$route.query.begin_time;
				}
				if(this.$route.query.end_time) {
					params.end_time = this.$route.query.end_time;
				}
				if (this.datetime) {
					params.begin_time = this.datetime[0];
					params.end_time = this.datetime[1];
				}
				if (this.$route.query.id) {
					params.store_id = this.$route.query.id
				}
				if (this.$route.query.is_live == 1){
					params.is_show_live = 1
				}
				getOrderList(params).then(response => {
					this.pageList = response.data.list;
					this.pageTotal = parseInt(response.data.count);
				})
			},
			handleSearch() {
				this.pageCurr = 1;
				this.getPageList();
			},
			handleClearSearch() {
				this.screenForm = Object.assign({}, defaultForm)
				this.datetime = '';
				this.pageCurr = 1;
				this.getPageList();
			},
			handleSizeChange(val) {
				this.pageSize = val;
			},
			handleCurrentChange(val) {
				this.pageCurr = val;
				this.getPageList();
			},
		},
		watch: {
			'$route': {
				handler: function(val, oldVal) {
					if (val) {
						this.pageCurr = 1;
						this.getPageList();
					}
				},
				deep: true
			}
		}
	}
</script>

<style lang="scss" scoped>
	.t-page {
		padding: 20px;
		.t-page-title {
			padding-bottom: 20px;

			.title {
				line-height: 20px;
				font-size: 16px;
				font-weight: bold;
			}
		}

		.t-page-screen {

			// padding-top: 18px;
			// border-top: 1px solid #EBEEF5;
			::v-deep .el-form {
				display: flex;
				flex-wrap: wrap;

				.el-form-item {
					width: 320px;
					flex-shrink: 0;
					display: flex;
					padding: 0 9px;
					margin-right: 0;
					margin-bottom: 16px;
					box-sizing: border-box;

					.el-form-item__label {
						min-width: 68px;
					}

					.el-form-item__content {
						flex: 1;
						width: 0;
					}
				}
			}

			.w100 {
				width: 100px;
			}

			.w_all {
				width: 100%;
			}

			.search-clear {
				cursor: pointer;
				margin-left: 12px;

				i {
					margin-right: 4px;
				}
			}
		}

		.t-page-table {
			::v-deep .el-table {
				th.el-table__cell {
					background-color: $--seller-thead-background-1;
				}
			}

			.link-item {
				color: $--seller-link-color;
				&:hover {
					color: $--seller-link-hover;
				}
			}
		}

		.t-page-bottom {
			padding: 15px 0;
			text-align: center;
		}
	}
</style>
